:root {
    --app-height: 100%;
}
#root, .course-page {
    display: flex;
    height: 100%;
    height: var(--app-height);
    flex-direction: column;
    -webkit-overflow-scrolling: touch;
    overflow-x:unset;
}
.bottom-map-container {
    flex: auto;
    overflow: hidden;
    flex-direction: row;
    display: flex;
    background-color: white;
}
.bottom-map-container-child {
    flex: auto;
    display: flex;
}
.search-here-button {
    position: absolute !important;
    left: 0;
    right: 0;
    margin-left: auto !important;
    margin-right: auto !important;
    top:20px;
    width: 200px;
    z-index:1;
    display:none;
}

.map-list-container{
    margin:10px;
    overflow-y:scroll;
    height:100%;
}
.map-search-options{
    margin-top:5px;
    margin-bottom:5px;
    display:flex;
}
.map-list-card {
    border-radius: 0 !important;
    border-bottom: 3px solid black !important;
    
}
@media only screen and (min-width: 1026px) {
    .map-list-card img {
        max-height: 180px;
    }
}
.list-item-description {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.popup{
    display:flex;
    flex-direction:row;
}

.popup-image > img {
    width:100%;
}
.popup-content{
    display:flex;
    flex-direction:column;
}
.popup-content > h3 {
    margin-top:5px;
}
.popup-content > a {
    width:70px;
    justify-content:center;
    align-self: center;
    margin-top:10px;
}
.map-filter{
    margin-right:5px;
}
@media only screen and (min-width: 1026px) {
    .map-filter {
        display: none !important;
    }
}
.mapboxgl-ctrl-geocoder{
    z-index:999;
    border-radius:10px;
}
.mapboxgl-ctrl-geocoder--icon-search{
    fill:black;
}
.map-course-quickview {
    z-index: 999;
    position: absolute !important;
    bottom: 10%;
    background-color: white;
    padding: 30px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 70%;   
}
@media only screen and (max-width: 780px) {
    .map-course-quickview {
        width: 100%;
        padding: 15px;
        bottom: 0%;
    }

}